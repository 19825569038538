import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Button, ChevronDown, Logo, NotificationIconWithDropdown } from '../..';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import { useAppSelector } from '../../../redux/store';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TDesktopNavigationBar, TUseNavigation } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CartIconWithItems } from '../desktop-navigation-bar/CartIconWithItems';
import { SearchWithDropdown } from '../desktop-navigation-bar/SearchWithDropdown';
import InboxIcon from '../icons/InboxIcon';
import MagnifierGlassIcon from '../icons/MagnifierGlassIcon';
import ModalComponent from '../modal/ModalComponent';
import { ProfileIconWithDropdown } from '../profile-icon-with-dropdown';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import TranslateIcon from '../icons/TranslateIcon';
import { palettes } from '../../../config';
import LanguageSelectorMobile from '../language-selector/language-selector-mobile';
import { Linking } from 'react-native';

type Props = {
	containerStyles?: TStyle;
	hasBackButton?: boolean;
	navigationBarProps?: Omit<TDesktopNavigationBar, 'containerStyles'>;
	hasIcons?: boolean;
	heading?: JSX.Element;
};

const MobileNavigationBarTop = ({
	containerStyles,
	navigationBarProps,
	hasBackButton = false,
	hasIcons = true,
	heading,
}: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const primary = usePrimaryColors();
	const isUserLoggedIn = useAppSelector(state => state.auth.isUserLoggedIn);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const pathName = window.location.pathname?.split('/')?.[1];
	const isNoShowAuthButtons = ['checkout-address', 'event'].includes(
		pathName
	);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [isLanguageModal, setIsLanguageModal] = useState(false);
	const onPressButton = () => {
		navigation.canGoBack()
			? navigation.goBack()
			: navigation.navigate('Home');
	};
	return (
		<>
			{isLanguageModal && (
				<ModalComponent
					openState={isLanguageModal}
					type="fullpage"
					onClose={() => {
						setIsLanguageModal(false);
					}}
					header={`${t('Choose Language')}`}
					subtitle={`${t('More translations coming soon!')}`}
				>
					<LanguageSelectorMobile />
				</ModalComponent>
			)}
			{showSearchModal && (
				<ModalComponent
					openState={showSearchModal}
					type="fullpage"
					onClose={() => {
						setShowSearchModal(false);
					}}
					isNotScrollable
					hasNoClose
				>
					<SearchWithDropdown
						type="list"
						onPress={() => {
							setShowSearchModal(false);
						}}
					/>
				</ModalComponent>
			)}
			<View style={[styles.container, containerStyles]}>
				{heading ? (
					heading
				) : (
					<>
						<View style={styles.profileButtonContainer}>
							{isUserLoggedIn ? (
								<>
									{hasBackButton && (
										<ChevronDown
											style={[
												styleChevron(),
												styles.chevron,
											]}
											onPress={onPressButton}
										/>
									)}
									<ProfileIconWithDropdown />
								</>
							) : (
								<Logo
									tint={primary?.[0]}
									style={styles.logo}
									width={38}
									height={38}
									scale={1.3}
									viewBox="0 0 38 38"
								/>
							)}
						</View>
						{hasIcons && isUserLoggedIn && (
							<View style={styles.iconContainer}>
								<MagnifierGlassIcon
									style={[styleIcon(), styles.magnifierIcon]}
									onPress={() => {
										setShowSearchModal(true);
									}}
								/>
								<CartIconWithItems />
								{typeOfAccount !== 'CONSUMER' && (
									<InboxIcon
										onPress={() =>
											navigation.navigate('Chat')
										}
										style={styleIcon()}
									/>
								)}
								<NotificationIconWithDropdown />
							</View>
						)}

						{(!isUserLoggedIn && !navigationBarProps?.hasNoLoginNav) && (
							<View style={styles.visitorCart}>
								{!isNoShowAuthButtons && (
									<>
										<Button
											onPress={() => {
												dispatch(setTypeOfAccount("SELLER"));
												dispatch(setPrimaryColors("SELLER"));
												dispatch(setSignupSidebarValues("SELLER"));
												navigation.navigate('PersonalInfo');
											}}
											title={t('Sell')}
											variant="primary"
											innerContainerProps={{
												style: {
													paddingLeft: '1.5rem',
													paddingRight: '1.5rem',
												},
											}}
										/>
										<Button
											onPress={() =>
												navigation.navigate('Login')
											}
											title={t('Login')}
											variant="grey"
											innerContainerProps={{
												style: {
													backgroundColor:
														'rgb(237, 239, 242)',
													paddingLeft: '1.5rem',
													paddingRight: '1.5rem',
												},
											}}
										/>
									</>
								)}
								<CartIconWithItems
									containerStyle={{ alignItems: 'center' }}
								/>
								{/* <Pressable
									onPress={() => setIsLanguageModal(true)}
								>
									<TranslateIcon
										fillColor={palettes.dark[4]}
									/>
								</Pressable> */}
							</View>
						)}
					</>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	magnifierIcon: {
		marginTop: 3,
	},
	logo: { minHeight: 38 },
	profileButtonContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	chevron: {
		transform: [{ rotate: '90deg' }],
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: getResponsiveStyle(15),
		paddingRight: getResponsiveStyle(15),
		paddingTop: getResponsiveStyle(15),
		paddingBottom: getResponsiveStyle(10),
		marginBottom: 32,
		width: '100vw',
		marginRight: getResponsiveStyle(10),
		flex: 1,
		position: 'absolute',
		zIndex: 9999,
		backgroundColor: '#fff',
	},
	iconContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '45%',
		maxWidth: 150,
		minWidth: 100,
		paddingRight: getResponsiveStyle(20),
	},
	visitorCart: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1rem',
		paddingRight: getResponsiveStyle(10),
	},
});

export default MobileNavigationBarTop;

function styleIcon() {
	return {
		height: getResponsiveStyle(20, 'dimensions'),
		width: getResponsiveStyle(20, 'dimensions'),
	};
}

function styleChevron() {
	return {
		width: getResponsiveStyle(7),
		height: getResponsiveStyle(12),
		marginRight: getResponsiveStyle(12),
		marginLeft: getResponsiveStyle(-5),
	};
}
