import React, { useEffect } from 'react';
import {
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	Dimensions,
	View,
} from 'react-native';
import {
	DesktopNavigationBar,
	MobileNavigationBarBottom,
	MobileNavigationBarTop,
} from '../..';
import { breakpoints } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import { TDesktopNavigationBar } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	hasPaddingRight?: boolean;
	hasNoPaddingLeftAndRight?: boolean;
	hasBackButton?: boolean;
	navigationBarProps?: Omit<TDesktopNavigationBar, 'containerStyles'>;
};

const DesktopTemplate: React.FC<Props> = ({
	children,
	hasBackButton,
	hasPaddingRight = true,
	hasNoPaddingLeftAndRight,
	navigationBarProps
}) => {
	let heightVal = Dimensions.get('window').height;
	useEffect(() => {
		heightVal = Dimensions.get('window').height;
	}, [Dimensions.get('window').height]);
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));
	return (
		<View style={{ height: heightVal }}>
			{isLessThanDesktopBase && (
				<View
					style={[
						{
							flex: 1,
							position: 'absolute',
							zIndex: 9999,
						},
						Platform.select({
							native: {
								top: 0,
							},
							default: {
								top: 0,
							},
						}),
					]}
				>
					<MobileNavigationBarTop
						hasBackButton={hasBackButton}
						navigationBarProps={navigationBarProps}
						containerStyles={styleNavMobile(hasPaddingRight)}
					/>
				</View>
			)}
			<ScrollView
				style={[
					styles.container,
					isLessThanDesktopBase && {
						paddingTop: getResponsiveStyle(65),
					},
				]}
			// stickyHeaderIndices={[0]}
			// invertStickyHeaders={true}
			>
				<KeyboardAvoidingView
					behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
					style={styles.keyboardAvoidingView}
				>
					<ScrollView
						showsVerticalScrollIndicator={false}
						contentContainerStyle={styleContentContainer(
							width,
							hasPaddingRight,
							hasNoPaddingLeftAndRight
						)}
					>
						<NavigationTemplate
							hasPaddingRight={hasPaddingRight}
							hasBackButton={hasBackButton}
							navigationBarProps={{
								hasCreateEvent:
									loggedInUserDetails?.typeOfAccount ===
									'SELLER',
							}}
						/>
						{children}
					</ScrollView>
				</KeyboardAvoidingView>
			</ScrollView>
			{isLessThanDesktopBase && (
				<View
					style={[
						{
							flex: 1,
							position: 'absolute',
						},
						// justifyContent: 'center',
						// alignItems: 'center',
						Platform.select({
							native: {
								bottom: 0,
							},
							default: {
								bottom: 0,
							},
						}),
					]}
				>
					{isUserLoggedIn && <MobileNavigationBarBottom />}
				</View>
			)}
		</View>
	);
};

const NavigationTemplate = ({
	hasPaddingRight,
	navigationBarProps,
	hasBackButton,
}: {
	navigationBarProps?: Omit<TDesktopNavigationBar, 'containerStyles'>;
	hasPaddingRight: boolean;
	hasBackButton?: boolean;
}) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View style={{ zIndex: 50 }}>
			{!isLessThanDesktopBase && (
				<DesktopNavigationBar
					containerStyles={styleNavDesktop(hasPaddingRight)}
					{...navigationBarProps}
				/>
			)}
			{/* {isLessThanDesktopBase && (
				<MobileNavigationBarTop
					hasBackButton={hasBackButton}
					containerStyles={styleNavMobile(hasPaddingRight)}
				/>
			)} */}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		minWidth: '100vw',
		// minHeight: '100vh',
		paddingBottom: getResponsiveStyle(65),
		position: 'relative',
	},
	containerMain: {
		// flex: 1,
		// alignItems: 'center',
		height: Dimensions.get('window').height,
	},
	keyboardAvoidingView: { flexDirection: 'row' },
});

export default DesktopTemplate;

const styleContentContainer = (
	width: number,
	hasPaddingRight: boolean,
	hasNoPaddingLeftAndRight?: boolean
) => {
	return {
		paddingLeft: hasNoPaddingLeftAndRight
			? 0
			: width >= breakpoints.desktopBase
				? 40
				: 16,
		paddingTop: width >= breakpoints.desktopBase ? 32 : 16,
		paddingRight: hasNoPaddingLeftAndRight
			? 0
			: hasPaddingRight
				? width >= breakpoints.desktopBase
					? 40
					: 16
				: 0,
		minHeight: '100vh',
	} as TStyle;
};

const styleNavMobile = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 16,
	};
};

const styleNavDesktop = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 40,
	};
};
