export const linking = {
	prefixes: ['https://ocpus-mobile.com', 'ocpus-client://'],
	config: {
		screens: {
			Home: '/',
			Welcome: '/welcome',
			SaveAs: '/save-as',
			Events: '/events',
			CreateEvent: '/create-event',
			EventDetail: '/event-detail',
			EventDuration: '/event-duration',
			EventUpload: '/event-upload',
			EventInvite: '/event-invite',
			EventHost: '/event-host',
			EventProduct: '/event-product',
			EventComplete: '/event-complete',
			EventLocation: '/event-location',
			CatchmentArea: '/event-area',
			LiveEvent: '/live-event',
			EventDescription: '/event/description',
			Network: '/network',
			Login: '/login',
			ForgotPassword: '/forgot-password',
			UserSelect: '/user-select',
			PersonalInfo: '/personal-info',
			SellerAgreement: '/seller-agreement',
			YourInformation: '/your-information',
			CreditCardInformation: '/credit-card',
			SettingCatchmentArea: '/setting-catchment-area',
			Availability: '/availability',
			SignupComplete: '/signup-complete',
			ProductsUpload: '/products-upload',
			Brands: '/brands',
			BrandDetails: '/brands/details',
			Profile: '/profile',
			UserProfile: 'profile/user',
			Chat: '/chat',
			reset: '/reset',
			Checkout: '/checkout',
			CheckoutAddress: '/checkout-address',
			PurchaseComplete: '/purchase-complete',
			OrderDetails: '/order-details',
			VerifyEmail: '/verify-email',
			MobileFacebookAuth: '/mobile-facebook-auth',
		},
	},
};
